<template>
  <div class="print">
    <div class="print-head" v-if="user.pressName">
      <div class="print-head-title">
        <router-link :to="{ name: 'home' }" class="print-head-nav none">
          <van-icon name="wap-home-o" />回首页
        </router-link>
        <p>{{ user.pressName }}</p>
        <span class="none"></span>
      </div>
    </div>

    <template v-if="loading">
      <template v-if="detailsData">
        <div
          class="print-mitem"
        >
          <div class="print-mitem-title-new">
            <div>状态：<span style="color: #777;">{{detailsData.state }}</span> </div>
            <div>标签打印状态：<span style="color: #777;">{{detailsData.printState }}</span> </div>
            <div>每卷的编号：<span style="color: #777;">{{detailsData.number }}</span> </div>
            <div>印刷任务编号：<span style="color: #777;">{{detailsData.taskNumber }}</span> </div>
            <div>首入日期：<span style="color: #777;">{{detailsData.fistTime }}</span> </div>
            <div>采购批次号：<span style="color: #777;">{{detailsData.batchNumber }}</span> </div>
            <div>材料标题：<span style="color: #777;">{{detailsData.title }}</span> </div>
            <div>材料标题：<span style="color: #777;">{{detailsData.gram }}</span> </div>
            <div>材料克重：<span style="color: #777;">{{detailsData.title }}</span> </div>
            <div>材料宽：<span style="color: #777;">{{detailsData.width }}</span> </div>
            <div>首入库房：<span style="color: #777;">{{detailsData.shorName }}</span> </div>
            <div>纸张供应商：<span style="color: #777;">{{detailsData.xs }}</span> </div>
            <div>印制项目：<span style="color: #777;">{{detailsData.PrintItemName }}</span> </div>
            <div>打印时间：<span style="color: #777;">{{detailsData.PrintTime }}</span> </div>
            <div>当前库房：<span style="color: #777;">{{detailsData.CurrentWarehouse }}</span> </div>
            
          </div>
          <div class="print-mitem-content"></div>
        </div>
      </template>
      <template v-else>
        <div class="print-nolist">
          <van-empty description="暂无数据" />
        </div>
      </template>
    </template>
    <template v-else>
      <div class="print-loading">
        <van-loading type="spinner" color="#0094ff" vertical
          >数据加载中...</van-loading
        >
      </div>
    </template>
  </div>
</template>

<script>
import { Dialog, Toast, Icon } from "vant";
export default {
  name: "det",
  data() {
    return {
      loading: false,
      id: this.$route.query.id == undefined ? "" : this.$route.query.id,// 778554510262931473
      work: "",
      user: "",
      token: "",
      detailsData: {},
    };
  },
  mounted() {
    if (this.$cookies.isKey("user")) {
        console.log('详情页面')
      this.user = this.$cookies.get("user");
      //console.log(this.user);
      this.token = { Authorization: "Bearer " + this.user.token };
      this.getDetailsList();
      
    } else {
      this.$router.replace({
        name: "login",
      });
    }
  },
  methods: {
    getDetailsList() {
      let params = {
        code: this.id,
      };
      this.$api.print.detailsList(params, this.token).then((res) => {
        console.log(res);
        let { tag, data } = res;
        if (tag == 1) {
          this.loading = true;
          this.detailsData = data;
          console.log('0000====',this.detailsData)
        } else {
          Toast("获取数据失败");
        }
      });
    },
  },
};
</script>

<style></style>
