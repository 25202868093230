<template>
  <div class="print">
    <div class="print-head" v-if="user.pressName">
      <div class="print-head-title">
        <router-link :to="{ name: 'home' }" class="print-head-nav none">
          <van-icon name="wap-home-o" />回首页
        </router-link>
        <p>{{ user.pressName }}</p>
        <span class="none"></span>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedtext"
        @load="onLoad"
      >
        <template v-if="list.length > 0">
          <div class="print-mitem" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
            <div>
                <div>调拨单号: <span class="gray-color">{{ item.outInNo }}</span></div>
                <div>采购合同编号: <span class="gray-color">{{ item.buyNo }}</span></div>
                <div>供料公司: <span class="gray-color">{{ item.supplierName }}</span></div>
                <div>存料公司: <span class="gray-color">{{ item.pressName }}</span></div>
                <div>计划调拨公斤: <span class="gray-color">{{ item.planInNum }}</span></div>
                <div>云鹤原白卷筒: <span class="gray-color">{{ item.title }}</span></div>
                <div>克重: <span class="gray-color">{{ item.gram }}</span></div>
                <div>宽: <span class="gray-color">{{ item.width }}</span></div>
            </div>
            <van-icon class="print-mlist-icon" name="arrow" />
          </div>
          
        </template>
        <template v-if="isEmpty">
          <div class="print-nolist">
            <van-empty description="暂无调拨单" />
          </div>
        </template>
      </van-list>
    </van-pull-refresh>

    <!-- <template v-else>
      <div class="print-nolist">
        <van-empty description="暂无数据" />
      </div>
    </template> -->
  </div>
</template>

<script>
import { Dialog, Toast, ImagePreview } from "vant";
export default {
  name: "deduct",
  data() {
    return {
      user: "",
      token: "",
      loading: false,
      finished: false,
      finishedtext: "没有更多了",
      refreshing: false,
      list: [],
      isEmpty: false,
    };
  },
  mounted() {
    if (this.$cookies.isKey("user")) {
      this.user = this.$cookies.get("user");
      //console.log(this.user);
      this.token = { Authorization: "Bearer " + this.user.token };
    } else {
      this.$router.replace({
        name: "login",
      });
    }
  },
  methods: {
    onLoad() {
      //console.log('被触发了')

      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
          //console.log('触发顶部')
        }
        this.$api.print.deductList({}, this.token).then((res) => {
          let { total, data, tag } = res;
          if (tag == 1) {
            if (data.length <= 0) {
              this.finishedtext = "";
              this.isEmpty = true;
            } else {
              this.list = data;
              this.isEmpty = false;
            }
            this.loading = false;
            if (this.list.length >= total) {
              this.finished = true;
              //console.log('底部没有可继续的了')
            }
          } else {
            //数据异常
            Toast.fail("获取数据失败");
          }
          //console.log(this.list)
        });
      }, 300);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    goDetail(item) {
        // 拉起二维码
        Toast.loading({
            message: "加载中...",
        });
        let that = this;
        setTimeout(() => {
            that.$router.push({
            name: "materials",
            query: {
                id: '2', // 主页
                outInNo: item.outInNo, // 调拨单编号
                outInId: item.outInId,// 调拨单id
                gram: item.gram, // 调拨单克重
                width:item.width, // 调拨单宽
            },
            });
        }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped> 
    .gray-color{
        color: #777;
    }
</style>
