import Vue from "vue";
import api from "./api/install";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import "vant/lib/index.css";
import tool from "./assets/tool";
import urlParams from "./assets/params";
import wechat from "@/assets/wechat";
import Vuecookie from "vue-cookies";
import VConsole from "vconsole";

Vue.prototype.tool = tool;
Vue.prototype.urlParams = urlParams;

Object.assign(Vue.prototype, {
  $wechat: wechat,
});

Vue.config.productionTip = false;
const vConsole = new VConsole();
Vue.use(api).use(vant).use(Vuecookie);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
