<template>
  <div class="print">
    <div class="print-head" v-if="user.pressName">
      <div class="print-head-title">
        <router-link
          :to="{ name: 'details', query: { wp: this.wp } }"
          class="print-head-nav none"
        >
          <van-icon name="arrow-left" />后退
        </router-link>
        <p>{{ user.pressName }}</p>
        <span class="none"></span>
      </div>
      <div class="print-head-info">
        <div class="title">
          <span>{{ fydid }}</span>
          <span>{{ fydname }}</span>
        </div>
        <div class="details" v-if="work">
          <span>{{ work.workingProcedure }}</span
          ><em>|</em><span>印制项目{{ work.workingProcedureCount }}个</span
          ><em>|</em
          ><span
            >剩余工量{{ workSum(progressList, work.unit) }}{{ work.unit }}</span
          >
        </div>
      </div>
    </div>
    <template v-if="loading">
      <template v-if="progressList.length > 0">
        <div
          class="print-mprogress"
          v-for="(item, index) in progressList"
          :key="index"
        >
          <div class="print-mprogress-main">{{ item.printItemName }}</div>
          <div class="print-mprogress-handle">
            {{ item.workingProcedure }} / 剩余工量
            <template v-if="work.unit == '令'">
              {{
                (item.num - (item.num * item.completionRate) / 100).toFixed(1)
              }}
            </template>
            <template v-else>
              {{
                Math.floor(
                  item.num - (item.num * item.completionRate) / 100 + 0.5
                )
              }}
            </template>
          </div>
          <van-slider
            v-model="item.completionRate"
            :step="5"
            :disabled="item.completionRateState"
            active-color="#ffad4a"
            bar-height="4px"
            @change="onChange(item.id, item.completionRate, index)"
          >
            <template #button>
              <div class="print-mprogress-slider">
                {{ item.completionRate }}
              </div>
            </template>
          </van-slider>
        </div>
      </template>
      <template v-else>
        <div class="print-nolist">
          <van-empty description="暂无数据" />
        </div>
      </template>
    </template>
    <template v-else>
      <div class="print-loading">
        <van-loading type="spinner" color="#0094ff" vertical
          >数据加载中...</van-loading
        >
      </div>
    </template>
  </div>
</template>

<script>
import { Dialog, Toast, Icon } from "vant";
import { set } from "vue";
export default {
  name: "pro",
  data() {
    return {
      loading: false,
      fydid: this.$route.query.id,
      fydname: this.$route.query.name,
      wp: this.$route.query.wp,
      work: "",
      progressList: [],
      user: "",
      token: "",
    };
  },
  mounted() {
    if (this.$cookies.isKey("user")) {
      this.user = this.$cookies.get("user");
      //console.log(this.user);
      this.token = { Authorization: "Bearer " + this.user.token };
      this.getProgressList();
    } else {
      this.$router.replace({
        name: "login",
      });
    }
  },
  methods: {
    getProgressList() {
      this.$api.print
        .progressList(
          { fydNo: this.fydid, workingProcedure: this.wp },
          this.token
        )
        .then((res) => {
          console.log(res);
          let { tag, data } = res;
          if (tag == 1) {
            this.loading = true;
            this.progressList = data.list;
            if (this.progressList.length > 0) {
              for (let i = 0; i < this.progressList.length; i++) {
                this.progressList[i].completionRateState = false;
                this.progressList[i].completionRateFormer =
                  this.progressList[i].completionRate;
              }
            }
            if (data.ywtFlowHead) {
              this.work = data.ywtFlowHead;
            }
          } else {
            Toast("获取数据失败");
          }
        });
    },
    workSum(arr, unit) {
      let c = 0;
      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          c += arr[i].num - (arr[i].num * arr[i].completionRate) / 100;
        }
        if (unit == "令") {
          //(arr[i].num - (arr[i].num * arr[i].completionRate) / 100).toFixed(1)
          c = c.toFixed(1);
        } else {
          //Math.floor(item.num - (item.num * item.completionRate) / 100 + 0.5)
          c = Math.floor(c + 0.5);
        }
      }
      return c;
    },
    updateProgress(id, pro, index) {
      if (this.progressList[index].completionRateState == false) {
        this.$api.print
          .UpdateProgress({ Id: id, rate: pro }, this.token)
          .then((res) => {
            let { tag } = res;
            if (tag == 1) {
              //更新成功改变前值
              this.progressList[index].completionRateFormer = pro;
              Toast.success("进度更新成功");
            } else {
              Toast.fail("进度更新失败");
              this.progressList[index].completionRate =
                this.progressList[index].completionRateFormer;
            }
          });
        console.log(
          "当前值:" +
            pro +
            "之前值:" +
            this.progressList[index].completionRateFormer
        );
      } else {
        Toast("慢点");
      }
    },
    onChange(id, pro, index) {
      if (this.progressList[index].completionRateFormer == 100) {
        this.progressList[index].completionRate =
          this.progressList[index].completionRateFormer;
        Toast("已完成");
      } else {
        if (pro == 100) {
          Dialog.alert({
            title: "完成提醒",
            message: "确认是否完工，提交后不可更改",
            showCancelButton: true,
            confirmButtonText: "提交",
          })
            .then(() => {
              this.updateProgress(id, pro, index);
              this.progressList[index].completionRateState = true;
            })
            .catch(() => {
              this.progressList[index].completionRate =
                this.progressList[index].completionRateFormer;
            });
        } else {
          this.updateProgress(id, pro, index);
        }
      }
    },
  },
};
</script>

<style></style>
