<template>
  <div class="receipt">
    <div class="receipt-title">
      <router-link :to="{ name: 'deduct' }" class="receipt-title-nav none">
        <van-icon name="arrow-left" />后退
      </router-link>
      <p>添加单据</p>
      <span class="none"></span>
    </div>
    <van-form class="receipt-form" @submit="onSubmit">
      <van-field v-model="title" label="单据标题" placeholder="请填写标题" />
      <van-field
        v-model="typename"
        label="单据类型"
        right-icon="arrow-down"
        placeholder="请选择单据类型"
        readonly
        @click="searchShowPopup = true"
      />
      <van-popup
        v-model="searchShowPopup"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-picker
          title="单据类型"
          show-toolbar
          :columns="typeList"
          default-index="2"
          @confirm="searchBtn"
          @cancel="searchShowPopup = false"
        />
      </van-popup>
      <van-field
        v-model="message"
        rows="3"
        autosize
        label="备注"
        type="textarea"
        maxlength="200"
        placeholder="请输入留言"
        show-word-limit
      />
      <div class="receipt-form-upload">
        <p>单据拍照</p>
        <van-uploader
          v-model="image"
          max-count="1"
          :after-read="afterRead"
          :before-read="beforeRead"
          accept="image/*"
        />
      </div>
      <van-button round block type="info" native-type="submit"
        >确认提交</van-button
      >
    </van-form>
  </div>
</template>

<script>
import fileUtils from "../assets/utils";
import ImageCompressor from "../assets/ImageCompressor";
import { Dialog, Toast, Notify } from "vant";
export default {
  name: "receipt",
  data() {
    return {
      loading: false,
      user: "",
      token: "",
      image: [],
      typeList: [
        "亏数图书扣款单",
        "读者投诉罚款单",
        "入库质量罚款单",
        "质量事故罚款单",
        "采集异常罚款单",
        "原辅料入库单",
        "油墨对账单",
        "热熔胶对账单",
        "印装费对账单",
        "纸张对账单",
      ],
      searchShowPopup: false,
      title: "",
      type: "",
      typename: "",
      message: "",
    };
  },
  watch: {
    image: function (n, o) {
      for (let j = 0; j < n.length; j++) {
        if (!this.image[j].url) {
          this.image.splice(j, 1);
        }
      }
    },
  },
  mounted() {
    if (this.$cookies.isKey("user")) {
      this.user = this.$cookies.get("user");
      //console.log(this.user);
      this.token = { Authorization: "Bearer " + this.user.token };
    } else {
      this.$router.replace({
        name: "login",
      });
    }
  },
  methods: {
    beforeRead(file) {
      return new Promise((resolve, reject) => {
        fileUtils.getOrientation(file).then((orient) => {
          if (orient && orient === 6 && fileUtils.ifHighThanIos13()) {
            let reader = new FileReader();
            let img = new Image();
            reader.onload = (e) => {
              img.src = e.target.result;
              img.onload = function () {
                const data = fileUtils.rotateImage(img, img.width, img.height);
                const newFile = fileUtils.dataURLtoFile(data, file.name);
                resolve(newFile);
              };
            };
            reader.readAsDataURL(file);
          } else {
            resolve(file);
          }
        });
      });
    },
    async afterRead(file) {
      this.handleUpload(file, 500, 0.6);
    },
    onSubmit(e) {
      if (this.title == "") {
        Notify({ type: "danger", message: "请填写单据标题" });
        return false;
      }
      if (this.type == "") {
        Notify({ type: "danger", message: "请选择单据类型" });
        return false;
      }
      if (this.image.length <= 0) {
        Notify({ type: "danger", message: "请拍照上传" });
        return false;
      }
      let url = this.image[0].url;
      let params = {
        title: this.title,
        type: this.type,
        imgUrl: url,
        remark: this.message,
      };
      this.$api.print.deductSave(params, this.token).then((res) => {
        let { tag, data, message } = res;
        if (tag == 1) {
          Dialog.alert({
            message: "您的单据已经成功保存",
            confirmButtonText: "回到列表",
          }).then(() => {
            //回到列表
            this.$router.replace({ name: "deduct" });
          });
        } else {
          Dialog.alert({
            title: "保存失败",
            message: message,
            confirmButtonText: "刷新页面",
          }).then(() => {
            //刷新页面
            this.$router.replace({ name: "deductadd" });
          });
        }
        //console.log(res)
      });
    },
    searchBtn(value, key) {
      this.searchShowPopup = false;
      this.typename = value;
      this.type = key + 1;
      console.log(this.type);
    },
    /*----------------*/
    /**
     * base64 转 Blob 格式 和file格式
     */
    base64UrlToBlob(urlData) {
      let arr = urlData.split(","),
        mime = arr[0].match(/:(.*?);/)[1], // 去掉url的头，并转化为byte
        bstr = atob(arr[1]), // 处理异常,将ascii码小于0的转换为大于0
        n = bstr.length,
        u8arr = new Uint8Array(n);
      //console.log("arr:" + arr);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // 转blob
      // return new Blob([u8arr], {type: mime})
      let filename = Date.parse(new Date()) + ".jpg";
      // 转file
      return new File([u8arr], filename, { type: mime });
    },
    /*
      压缩图片
      file：文件(类型是图片格式)，
      obj：文件压缩后对象width， height， quality(0-1)
      callback：容器或者回调函数
    */
    photoCompress(file, obj, callback) {
      let that = this;
      let ready = new FileReader();
      /* 开始读取指定File对象中的内容. 读取操作完成时,返回一个URL格式的字符串. */
      ready.readAsDataURL(file);
      ready.onload = function () {
        let re = this.result;
        console.log(re);
        that.canvasDataURL(re, obj, callback); // 开始压缩
      };
    },
    /* 利用canvas数据化图片进行压缩 */
    /* 图片转base64 */
    canvasDataURL(path, obj, callback) {
      let img = new Image();
      img.src = path;
      img.onload = function () {
        let that = this; // 指到img
        // 默认按比例压缩
        let w = that.width,
          h = that.height,
          scale = w / h;
        w = obj.width || w;
        h = obj.height || w / scale;
        let quality = 0.7; // 默认图片质量为0.7
        // 生成canvas
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        // 创建属性节点
        let anw = document.createAttribute("width");
        anw.nodeValue = w;
        let anh = document.createAttribute("height");
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        ctx.drawImage(that, 0, 0, w, h);
        // 图像质量
        if (obj.quality) {
          quality = obj.quality;
          //console.log("quality:" + quality);
        }
        // quality值越小，所绘制出的图像越模糊
        let base64 = canvas.toDataURL("image/jpeg", quality);
        // 回调函数返回base64的值
        callback(base64);
      };
    },
    //  返回file文件，调用接口执行上传
    async uploadLice(files) {
      const formData = new FormData();
      formData.append("FileData", files);
      return await this.$api.print.deductUpload(formData, this.token);
    },
    //图片上传 1.file(原文件) 2.size(判断大小-kb) 3.quality(质量)
    handleUpload(file, size, quality) {
      file.status = "uploading";
      file.message = "上传中...";
      let that = this;
      let files = file.file;
      if (files === undefined) {
        return;
      }
      //KB 判断是否压缩图
      if (files.size / 1024 > size) {
        // 文件大于100K（根据需求更改），进行压缩上传
        this.photoCompress(files, { quality: quality }, function (base64Codes) {
          // console.log("压缩后：" + base.length / 1024 + " " + base);
          let bl = that.base64UrlToBlob(base64Codes);
          // file.append('file', bl, 'file_' + Date.parse(new Date()) + '.jpg') // 文件对象
          // 请求图片上传接口
          that.uploadLice(bl).then((res) => {
            file.status = "done";
            if (res && res.data) {
              that.image.push({ url: res.data });
            } else {
              file.status = "failed";
              file.message = "上传失败";
              return;
            }
          });
        });
      } else {
        // 判断大小-直接上传原图
        this.uploadLice(files).then((res) => {
          file.status = "done";
          if (res && res.data) {
            that.image.push({ url: res.data });
          } else {
            file.status = "failed";
            file.message = "上传失败";
            return;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.receipt {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;

    &-nav {
      font-size: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      .van-icon {
        font-size: 48px !important;
      }
    }

    p {
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      flex: 1;
    }
    .none {
      width: 140px;
    }
  }

  &-form {
    flex: 1;
    padding: 30px;

    .van-cell {
      line-height: 2;
      font-size: 28px;
      padding: 30px 20px;
    }

    .van-button--info {
      margin-top: 60px;
      background: linear-gradient(to right, #3f8fff, #0750ff);
    }

    &-upload {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 30px;

      p {
        padding: 0 80px 0 20px;
        font-size: 28px;
        color: #646566;
      }
    }
  }
}
</style>
