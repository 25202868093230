<template>
    <div class="print">
      <div class="print-head" v-if="user.pressName">
        <div class="print-head-title">
          <router-link :to="{ name: 'home' }" class="print-head-nav none">
            <van-icon name="wap-home-o" />回首页
          </router-link>
          <p>{{ user.pressName }}</p>
          <span class="none"></span>
        </div>
      </div>
      <van-search
            v-model="searchText"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch"
            >
            <template #action>
                <div @click="onClickButton">搜索</div>
            </template>
        </van-search>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedtext"
          @load="onLoad"
        >
          <template v-if="list.length > 0">
            <div class="print-mitem" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
              <div>
                <div>任务号：<span class="gray-color1">{{ item.taskName }}</span></div>
                <div>图书：<span class="gray-color">{{ item.bookName }}</span></div>
                <div>数量：<span class="gray-color">{{ item.allPrintNums }}</span></div>
              </div>
              <van-icon class="print-mlist-icon" name="arrow" />
            </div>
            
          </template>
          <template v-if="isEmpty">
            <div class="print-nolist">
              <van-empty description="暂无生产单" />
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
  
      <!-- <template v-else>
        <div class="print-nolist">
          <van-empty description="暂无数据" />
        </div>
      </template> -->
    </div>
  </template>
  
  <script>
  import { Dialog, Toast, ImagePreview } from "vant";
  export default {
    name: "deduct",
    data() {
      return {
        user: "",
        token: "",
        loading: false,
        finished: false,
        finishedtext: "没有更多了",
        refreshing: false,
        list: [],
        originList:[],// 原始数据
        isEmpty: false,
        searchText: "",
      };
    },
    mounted() {
      if (this.$cookies.isKey("user")) {
        this.user = this.$cookies.get("user");
        //console.log(this.user);
        this.token = { Authorization: "Bearer " + this.user.token };
      } else {
        this.$router.replace({
          name: "login",
        });
      }
    },
    methods: {
      onLoad() {
        //console.log('被触发了')
  
        setTimeout(() => {
          if (this.refreshing) {
            this.list = [];
            this.refreshing = false;
            //console.log('触发顶部')
          }
          this.$api.print.mainList({}, this.token).then((res) => {
            let { total, data, tag } = res;
            if (tag == 1) {
              if (data.length <= 0) {
                this.finishedtext = "";
                this.isEmpty = true;
              } else {
                this.isEmpty = false;
                this.list = data;
                this.originList = data;
              }
              this.loading = false;
              if (this.list.length >= total) {
                this.finished = true;
                //console.log('底部没有可继续的了')
              }
            } else {
              //数据异常
              Toast.fail("获取数据失败");
            }
            //console.log(this.list)
          });
        }, 300);
      },
      onRefresh() {
        // 清空列表数据
        this.finished = false;
        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
      goDetail(item) {
          // 拉起二维码
          Toast.loading({
              message: "加载中...",
          });
          let that = this;
          setTimeout(() => {
              that.$router.push({
              name: "materials",
              query: {
                id: '1', // 主页
                taskName: item.taskName, // 任务号
                taskId: item.taskId,// 任务id
              },
              });
          }, 1000);
      },
      onSearch(){
        
      },
      onClickButton(){
        
      }
    },
    // 监听searchText，当值改变时，对list中的taskName进行模糊搜索
    watch: {
      searchText(val) {
        this.list = this.originList.filter((item) => item.taskName.toLowerCase().includes(val.toLowerCase()));
      },
    },
  };
  </script>
  
  <style lang="scss" scoped> 
      .gray-color{
          color: #777;
      }
      .gray-color1{
        font-weight: bold;
      }
  </style>
  